import { useEffect } from 'react';

function Custom404() {
  useEffect(() => {
    // Redirect to the 404.html page
    window.location.href = 'page-404.html';
  }, []);

  // Optionally, return null or some loading indicator while redirection is in progress
  return null;
}

export default Custom404;