import React, { createContext, useState, useEffect } from 'react';
import { remoteConfig, log } from './firebase-config';
import { fetchAndActivate, getString } from 'firebase/remote-config';

// Create a context
export const RemoteConfigContext = createContext();

// Create a provider component
export const RemoteConfigProvider = ({ children }) => {
  const [config, setConfig] = useState({
    blogUrl: 'https://blog.sankalpchauhan.me', // default values
    resumeUrl: null,
    // ... more values
  });

  useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then(() => {
        setConfig({
          blogUrl: getString(remoteConfig, 'blogUrl'),
          resumeUrl: getString(remoteConfig, "resumeUrl")
          // ... more values
        });
        console.log("Remote Config Fetch Test: fetch only once", setConfig.blogUrl)
      })
      .catch((err) => {
        console.error("Failed to fetch remote config:", err);
      });
  }, []);
   // Extend context value to include log function
   const contextValue = {
    config,
    log, // add log function here
  };
  return (
    <RemoteConfigContext.Provider value={contextValue}>
      {children}
    </RemoteConfigContext.Provider>
  );
};