import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import About from './components/About';
import Work from './components/Work';
import Blog from './components/Blog';
import Contact from './components/Contact';
import ParticlesBackground from './components/ParticlesBackground';
import AnimatedBackground from './components/AnimatedBackground';
import VantaBackground from './components/VantaBackground';
import ConfettiBackground from "./components/ConfettiBackground";
import AppIconAnimation from './components/AppIconAnimation';
import GlassmorphismDialog from './GlassmorphismDialog/GlassmorphismDialog';
import Custom404 from './components/Custom404';
// import { remoteConfig } from './firebase/firebase-config';
// import { fetchAndActivate, getString } from 'firebase/remote-config';
import { RemoteConfigProvider } from './firebase/RemoteConfigContext';

const mainContainerStyle = {
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};


function App() {

  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedApp, setSelectedApp] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToNextSection = () => {
    const aboutSection = document.querySelector('.about-container');
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <RemoteConfigProvider>
    <Router>
      <GlassmorphismDialog
        visible={dialogVisible}
        message="This is an Android package. Do you want to continue downloading?"
        onYes={() => {
          window.open(selectedApp.url, "_blank");
          setDialogVisible(false);
        }}
        onNo={() => {
          setDialogVisible(false);
        }}
      />
      <VantaBackground/>
      <ParticlesBackground/>
      <ConfettiBackground/>
      <AppIconAnimation
        setSelectedApp={setSelectedApp}
        setDialogVisible={setDialogVisible} />
      <div style={mainContainerStyle}>
        <Header >
        <button
      className={`scroll-button ${scrollPosition > 50 ? 'show' : ''}`}
      onClick={scrollToNextSection}
    >
      &#x25B2;
    </button>
          </Header>
        <Routes>
          <Route path="/" element={<About />} />
          {/* <Route path="/work" element={<Work />} /> */}
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Custom404 />} />
        </Routes>
      </div>
    </Router>
    </RemoteConfigProvider>
  );
}

export default App;
