import React, { useState } from "react";
import './GlassmorphismDialog.css';

const GlassmorphismDialog = ({ visible, message, onYes, onNo }) => {
    const [animationClass, setAnimationClass] = useState("");
    const handleNoClick = () => {
        setAnimationClass("slide-out");
        setTimeout(() => {
          onNo();
        }, 500);
      };
  if (!visible) return null;

  return (
    <div className="glassmorphism-dialog-container">
      <div className="glassmorphism-dialog">
        <p>{message}</p>
        <div className="glassmorphism-dialog-buttons">
          <button className="yes" onClick={onYes}>
            Yes
          </button>
          <button className="dialog-button no" onClick={handleNoClick}>
            No
            </button>
        </div>
      </div>
    </div>
  );
};

export default GlassmorphismDialog;
