import React, { useRef, useEffect } from "react";
import confetti from "canvas-confetti";
import debounce from "lodash/debounce";

const ConfettiBackground = () => {
  const canvasRef = useRef();

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener("resize", handleResize);

    const updateConfetti = debounce((e) => {
      const colors = ["#FFD700"];

      confetti({
        particleCount: 100,
        angle: 90,
        spread: 500,
        origin: { x: e.clientX / window.innerWidth, y: e.clientY / window.innerHeight },
        colors: colors,
        ticks: 400,
        zIndex: -1,
        disableForReducedMotion: true,
        particleSpeed: 0.5
        
      });
    });

    window.addEventListener("click", updateConfetti);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("click", updateConfetti);
    };
  }, []);

  return <canvas ref={canvasRef} style={{ position: "fixed", top: 0, left: 0, zIndex: -1 }} />;
};

export default ConfettiBackground;
