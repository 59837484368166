import React, { useEffect, useState } from "react";
import ParticlesBg from "particles-bg";

const ParticlesBackground = () => {
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });

    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const config = {
    num: [4, 8],
    rps: 0.4,
    radius: [5, 50],
    life: [1.5, 3.5],
    v: [1, 3],
    tha: [-30, 30],
    alpha: [0.5, 0.1],
    scale: [0.1, 0.4],
    position: "all",
    color: ["#FFD700"],
    cross: "dead",
    random: 1,
    g: 0.5,
    mouseForce: 50000, // Add this property to apply force to particles when the cursor is near them
  };

  return (
    <ParticlesBg
      type="custom"
      config={config}
      bg={true}
      width={windowSize.width}
      height={windowSize.height}
    />
  );
};

export default ParticlesBackground;
