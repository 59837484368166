import React from 'react';

const Contact = () => {
  return (
    <header>
      <h1>User's Personal Website</h1>
      {/* Add navigation links */}
    </header>
  );
};

export default Contact;
