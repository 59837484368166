import React from 'react';

const VantaBackground = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
        backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/personal-apps-67d82.appspot.com/o/images%2Fasset%2Fheader-background.jpg?alt=media&token=1c59ddfb-ac3a-463f-be11-1c2af235c1a7)', // Set the background image
        backgroundSize: 'cover', // Ensure it covers the full background
        backgroundPosition: '1% center', // Center the background image
        backgroundRepeat: 'no-repeat', // Do not repeat the image
      }}
    />
  );
};

export default VantaBackground;
