import React from 'react';
import './About.css';

const About = () => {
  // return (
  //   <div className="about-container">
  //     <h2 className="about-header">About</h2>
  //     <p className="about-text">
  //       This is the About section. You can add your content here.
  //     </p>
  //   </div>
  // );
};

export default About;
