import React, { useState, useEffect } from "react";
import "./AppIconAnimation.css";
import MarqueeSlider from "react-marquee-slider";
import GlassmorphismDialog from '../GlassmorphismDialog/GlassmorphismDialog';


function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
}

function chunkArray(array, size) {
  const chunkedArr = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArr.push(array.slice(i, i + size));
  }
  return chunkedArr;
}

const AppIconAnimation = ({setSelectedApp, setDialogVisible }) => {


  const icons = [
    [
      {icon: 'https://firebasestorage.googleapis.com/v0/b/personal-apps-67d82.appspot.com/o/images%2Fheader%2Fpaytm_mloyal.png?alt=media&token=76e2e7d4-20c6-43e2-9067-75581ae9f4b2', url: 'https://paytmmloyal.com/'},
      {icon: 'https://firebasestorage.googleapis.com/v0/b/personal-apps-67d82.appspot.com/o/images%2Fheader%2Fixigo_logo.png?alt=media&token=09d513b5-6f4c-473f-adbe-4681588b93eb', url: 'https://www.ixigo.com'},
      {icon: 'https://firebasestorage.googleapis.com/v0/b/personal-apps-67d82.appspot.com/o/images%2Fheader%2Fsquareboat.png?alt=media&token=0055144c-2994-4427-b517-efe48bc23de9', url: 'https://squareboat.com/'}, 
      {icon: 'https://firebasestorage.googleapis.com/v0/b/personal-apps-67d82.appspot.com/o/images%2Fheader%2Fgrapecity_new.png?alt=media&token=3c80a4a7-4ec1-4899-90b4-db250af40e97', url: 'https://www.grapecity.com/'}, 
      {icon: 'https://firebasestorage.googleapis.com/v0/b/personal-apps-67d82.appspot.com/o/images%2Fheader%2Fcypherock.png?alt=media&token=a63acfec-7baa-41bf-8b11-2b4ad10acef5', url: 'https://www.cypherock.com/'},
    ],
    [
      {icon: 'https://firebasestorage.googleapis.com/v0/b/personal-apps-67d82.appspot.com/o/images%2Fheader%2Fixigo%20logo.png?alt=media&token=cabb4c2f-8290-41e2-b276-689e451a2e13', url: 'https://play.google.com/store/apps/details?id=com.ixigo.train.ixitrain&hl=en&gl=IN'}, 
      {icon: 'https://firebasestorage.googleapis.com/v0/b/personal-apps-67d82.appspot.com/o/images%2Fheader%2Fatadel.png?alt=media&token=1026946f-7a43-4578-90d9-6c8b729276a2', url: 'https://play.google.com/store/apps/details?id=com.atadel.consumer&hl=en&gl=CA'},
      {icon: 'https://firebasestorage.googleapis.com/v0/b/personal-apps-67d82.appspot.com/o/images%2Fheader%2Fheyoye.png?alt=media&token=528a7ace-b096-4f98-8740-4fe1ed5230ff', url: 'https://play.google.com/store/apps/details?id=com.app.aiisma&hl=en_IN&gl=IN'},
      {icon: 'https://firebasestorage.googleapis.com/v0/b/personal-apps-67d82.appspot.com/o/images%2Fheader%2FScreenshot%202023-12-10%20at%202.48.49%E2%80%AFPM.png?alt=media&token=ab02ef3c-fd57-47f4-89c9-43c03c1b7093', url: 'https://apps.apple.com/ae/app/allegiance-real-estate/id1584081835'},
    ],
    [
      {icon: 'https://firebasestorage.googleapis.com/v0/b/personal-apps-67d82.appspot.com/o/images%2Fheader%2Fflynd.png?alt=media&token=1958bcec-630e-4a4a-ac39-5279089c9800', url: 'https://flyndapp.page.link/get', isAPK:true},
      {icon: 'https://firebasestorage.googleapis.com/v0/b/personal-apps-67d82.appspot.com/o/images%2Fheader%2Fgfg.jpeg?alt=media&token=cba8d341-d74e-42d0-9c04-54a0a0ea3aac', url: 'https://geeksforgeeks.org/'},
      {icon: 'https://firebasestorage.googleapis.com/v0/b/personal-apps-67d82.appspot.com/o/images%2Fheader%2FKanban%20Board.png?alt=media&token=2c5d977e-72a6-40b6-b04a-bcefe1738625', url: 'https://play.google.com/store/apps/details?id=me.sankalpchauhan.kanbanboard'},
      {icon: 'https://firebasestorage.googleapis.com/v0/b/personal-apps-67d82.appspot.com/o/images%2Fheader%2Fdamac_agents.png?alt=media&token=84675483-fee4-4ee6-8718-a485ab1d5b04', url: 'https://www.damacproperties.com/en/' }
    ],
    [
      {icon: 'https://firebasestorage.googleapis.com/v0/b/personal-apps-67d82.appspot.com/o/images%2Fheader%2Flikemaster.png?alt=media&token=72d43604-4aa7-439b-b75c-1266ea7938d2', url: 'https://play.google.com/store/apps/details?id=com.swipe.likemaster&hl=en&gl=IN'}, 
      {icon: 'https://firebasestorage.googleapis.com/v0/b/personal-apps-67d82.appspot.com/o/images%2Fheader%2Fotoclick.png?alt=media&token=260527a9-4cac-4b24-acdb-28eec4620d3b', url: 'https://play.google.com/store/apps/details?id=com.maroonlabs.automation&hl=en&gl=US'}, 
      {icon: 'https://firebasestorage.googleapis.com/v0/b/personal-apps-67d82.appspot.com/o/images%2Fheader%2Fchatnotes.png?alt=media&token=1f4c4324-b8d6-498b-8e20-02e63241e0d3', url: 'https://play.google.com/store/apps/details?id=com.maroonlabs.notes&hl=en&gl=IN'}, 
      {icon: 'https://firebasestorage.googleapis.com/v0/b/personal-apps-67d82.appspot.com/o/images%2Fheader%2Fpositively.png?alt=media&token=d376152e-d6c1-473e-a1a5-c5fb44a1d746', url: 'https://play.google.com/store/apps/details?id=me.sankalpchauhan.positively'},
    ],
    // [
    //   {icon: 'https://firebasestorage.googleapis.com/v0/b/personal-apps-67d82.appspot.com/o/images%2Fheader%2Fpositively.png?alt=media&token=d376152e-d6c1-473e-a1a5-c5fb44a1d746', url: 'https://play.google.com/store/apps/details?id=me.sankalpchauhan.positively'},
    //   {icon: 'https://firebasestorage.googleapis.com/v0/b/personal-apps-67d82.appspot.com/o/images%2Fheader%2Fpubgpriemeirleague.png?alt=media&token=eae1bc27-dcd5-41c4-9779-217caac19392', url: 'https://sankalpchauhan.me/projects/PubgPremierLeague/assets/PUBGPremierLeague-beta-release.apk', isAPK: true},
    //   {icon: 'https://firebasestorage.googleapis.com/v0/b/personal-apps-67d82.appspot.com/o/images%2Fheader%2Fcryosphere.png?alt=media&token=08f5a956-ecfe-47dd-8fc3-b5a8664f6654', url: 'https://github.com/sankalpchauhan-me/FindMyCryosphere-Team-Sudo'},
    //   {icon: 'https://firebasestorage.googleapis.com/v0/b/personal-apps-67d82.appspot.com/o/images%2Fheader%2FKanban%20Board.png?alt=media&token=2c5d977e-72a6-40b6-b04a-bcefe1738625', url: 'https://play.google.com/store/apps/details?id=me.sankalpchauhan.kanbanboard'},
    // ],
  ];

  const [shuffledIcons, setShuffledIcons] = useState([]);

  useEffect(() => {
      // Create a deep copy of the icons array
      const iconsCopy = JSON.parse(JSON.stringify(icons));
      // Shuffle each row in the copy
      iconsCopy.forEach(row => shuffleArray(row));
      setShuffledIcons(iconsCopy);
  }, []); // Dependency array is empty to run only once


  const [clickedIconIndex, setClickedIconIndex] = useState(null);
  const [clickedIconPosition, setClickedIconPosition] = useState({});
  const handleAppClick = (app, callback) => {
    if (app.isAPK) {
      callback(app);
    } else {
      window.open(app.url, '_blank');
    }
  };
  const repeatedIcons = shuffledIcons.map(row => [...row, ...row, ...row]);
  const appIconRows = repeatedIcons.map((row, rowIndex) => (
    <MarqueeSlider
      key={rowIndex}
      direction={rowIndex % 2 === 0 ? "ltr" : "rtl"}
      velocity={10}
      resetAfterTries={500}
      isInView={true}
    >
      {row.map((app, iconIndex) => (
        <div
          className="app-icon-wrapper"
          onClick={() => {
            setClickedIconIndex(`${rowIndex}-${iconIndex}`);
            setTimeout(() => {
              handleAppClick(app, (selectedApp) => {
                setSelectedApp(selectedApp);
                setDialogVisible(true);
              });
              setClickedIconIndex(null);
            }, 300);
          }}
        >
          <img
            width={200}
            height={200}
            key={iconIndex}
            src={app.icon}
            alt={`App icon ${iconIndex}`}
            className={`app-icon ${clickedIconIndex === `${rowIndex}-${iconIndex}` ? "app-icon-clicked" : ""}`}
            style={{ borderRadius: '8px' }}
          />
        </div>
      ))}
    </MarqueeSlider>
  ));
  

  return (
    <div className="app-icons-container">
      <div className="app-icons-background"></div>
      {appIconRows}
    </div>
  );
};


export default AppIconAnimation;
