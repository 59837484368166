import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useSpring, animated, useTransition } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub, faMedium, faTwitter, faStackOverflow } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faCode } from '@fortawesome/free-solid-svg-icons';
import Typewriter from 'typewriter-effect';
import './Header.css';
import { RemoteConfigContext } from './../firebase/RemoteConfigContext'; 


const textStyle = {
  color: '#A9A9A9'
};

const Header = () => {
  const { config, log } = useContext(RemoteConfigContext);

  const handleNavItemClick = (itemName) => {
    log('nav_item_click', { item: itemName.name });
  };

  var resumeUrl = (!config.resumeUrl || config.resumeUrl.trim() === '') ? "assets/SankalpChauhanResume.pdf" : config.resumeUrl;

  const navItems = [
    { name: 'Contact', path: 'https://form.typeform.com/to/RYC9km', type: 'external', target: "no", highlight:false },
    { name: 'Blog', path: `${config.blogUrl}`,type: 'external', target: "no", highlight:false },
    { name: 'Résumé', path: `${resumeUrl}`, type: 'external', highlight: true },
  ];

  const [scrollPosition, setScrollPosition] = useState(0);
  const navLinkStyle = {
    color: 'white',
    textDecoration: 'none',
  };

  const renderNavItem = (item, index) => {
    const isHighlighted = item.highlight;

    const typographyClass = isHighlighted ? "" : "shining-text";
    const buttonClass = isHighlighted ? "nav-button-highlight" : "nav-button";

    const handleClick = () => handleNavItemClick(item.name);

    if (item.type === 'internal') {
      return (
        <NavLink
        to={item.path}
        style={navLinkStyle}
        activeClassName="active"
      >
        <Button className={buttonClass} key={index} color="inherit" onClick={handleClick}>
            <Typography
              fontWeight="bold"
              textAlign="center"
              className = {typographyClass}
              sx={{
                fontSize: { xs: "1rem", sm: "1.25rem", md: "1.25rem" },
                textTransform: "none",
              }}
            >
              {item.name}
            </Typography>
        </Button>
                  </NavLink>
      );
    } else if (item.type === 'external') {
      var text = "_blank"
      if(item.target==="no"){
        text = ""
      }
      return (
        <a
        href={item.path}
        style={navLinkStyle}
        target= {text}
        rel="noopener noreferrer"
      >
        <Button className={buttonClass} key={index} color="inherit" onClick={handleClick}>
            <Typography
              className = {typographyClass}
              fontWeight="bold"
              textAlign="center"
              sx={{
                fontSize: { xs: "1rem", sm: "1.25rem", md: "1.25rem" },
                textTransform: "none",
              }}
            >
              {item.name}
            </Typography>
        </Button>
        </a>
      );
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const calculateAnimationProgress = () => {
    const progress = Math.max(0, Math.min(1, scrollPosition / window.innerHeight));
    return progress;
  };

  const header = React.useRef();

  useEffect(() => {
    if (header.current) {
      const progress = calculateAnimationProgress();
      const rotation = (180 - progress) * 180;
      header.current.style.transform = `perspective(1200px) rotateX(${rotation}deg)`;
    }
  }, [scrollPosition]);

  const fadeInAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 2000 },
  });

  const socialIcons = [
    { icon: faLinkedin, url: "https://www.linkedin.com/in/sankalpchauhan-me/" },
    { icon: faGithub, url: "https://github.com/sankalpchauhan-me" },
//    { icon: faMedium, url: "https://medium.com" },
    { icon: faEnvelope, url: "mailto:sankalp@sankalpchauhan.me" },
    { icon: faTwitter, url: "https://twitter.com/iSankalpChauhan" },
    { icon: faStackOverflow, url: "https://stackoverflow.com/users/8779135/sankalp" },
  ];

  const typewriterTexts = [
    'Software Engineer',
    '#Mobile',
    '#Cybersecurity',
    '#Backend',
  ];
  const [index, setIndex] = useState(0);
    const transitions = useTransition(index, {
      from: { opacity: 0, transform: 'translate3d(0, -40px, 0)', fontSize: '1rem' },
      enter: { opacity: 1, transform: 'translate3d(0, 0px, 0)', fontSize: '2.5rem' },
      leave: { opacity: 0, transform: 'translate3d(0, 40px, 0)', fontSize: '1rem' },
      config: { duration: 500 },
    });

    useEffect(() => {
      const interval = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % typewriterTexts.length);
      }, 3000);
      return () => clearInterval(interval);
    }, []);


  return (
    <div style={{ position: 'relative', zIndex: 2, height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Container maxWidth="md">
        <animated.div style={fadeInAnimation}>
          <Box
            ref={header}
            className="glassmorphism header-animation"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            padding={2}
          >
            <animated.div style={fadeInAnimation}>
            <Typography
                color="#FFFFFF"
                variant="h1"
                gutterBottom
                align="center"
                className="shining-text"
                sx={{ fontSize: { xs: "3rem", sm: "4rem", md: "5rem" } }}
                fontWeight="525"
              >
                Sankalp Chauhan
            </Typography>
            <Box
            align="center">
            <Typography
              color="#FFFFFF"
              variant="h4"
              gutterBottom
              align="center"
              className="typewriter-text professional-background"
              sx={{
                fontSize: { xs: "0.75rem", sm: "1.25rem", md: "1.75rem" },
                marginBottom: "16px",
              }}
            >
              <Typewriter
                options={{
                  strings: typewriterTexts,
                  autoStart: true,
                  loop: true,
                  delay: 75,
                  deleteSpeed: 50,
                  pauseFor: 2000,
                }}
              />
            </Typography>
            </Box>
            <Typography className="shining-text" gutterBottom align="center" sx={{ fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" }, ...textStyle }}>
            I'm a software engineer passionate about bringing ideas to life, creating user-friendly applications, and dedicated to building inclusive, sustainable technology that pushes the envelope.
            </Typography>
          </animated.div>
          <Box mt={4}>
            <div className="flex-container">
              {navItems.map(renderNavItem)}
            </div>
          </Box>

          <Box mt={4}>
          <div className="flex-container">
                    {socialIcons.map((social, index) => (
                      <a
                        key={index}
                        href={social.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="social-media-icon"
                        sx={{
                          margin: { xs: '0 4px', sm: '0 6px', md: '0 8px' },
                          padding: { xs: '4px 8px', sm: '8px 16px', md: '8px 16px' }
                        }}
                      >
                        <FontAwesomeIcon
                          icon={social.icon}
                          style={{ color: "white", fontSize: { xs: "34px", sm: "36px", md: "36px" }
                        }}
                        />
                      </a>
                    ))}
                    </div>
                  </Box>
          </Box>
        </animated.div>
      </Container>
    </div>
  );
};

export default Header;