import { initializeApp } from 'firebase/app';
import { getRemoteConfig } from 'firebase/remote-config';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyA6i7JvlN9j-wRuH0WUHqz0H57jUPmQkno",
    authDomain: "personal-apps-67d82.firebaseapp.com",
    projectId: "personal-apps-67d82",
    storageBucket: "personal-apps-67d82.appspot.com",
    messagingSenderId: "662200873369",
    appId: "1:662200873369:web:9e6c4f81adc00a316b2308",
    measurementId: "G-3L22JCLLX1"
  };

const app = initializeApp(firebaseConfig);
let fetchIntervalMillis = 3600000; // 1 hour

if (process.env.NODE_ENV === 'development') {
    fetchIntervalMillis = 0; // Immediate fetch for development
  }

// Initialize Remote Config
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = fetchIntervalMillis;
const analytics = getAnalytics(app);

// Custom log function
const log = (eventName, eventParams) => {
    console.log(eventName);
    logEvent(analytics, eventName, eventParams);
  };

// You can then export remoteConfig for use in other parts of your application
export { log, remoteConfig };